import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://423e8f809cd78c25599c6316afb84652@o4507891270746112.ingest.us.sentry.io/4507891276840960",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});